import React from "react"
import PropTypes from "prop-types"

import { Container } from "../index"
import styles from "./about.module.scss"

const About = ({ html, title, subTitle }) => (
  <section id="about" className={styles.about}>
    <Container className={styles.container}>
      <h4>{subTitle}</h4>
      <h2 className="h1">{title}</h2>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Container>
  </section>
)

About.propTypes = {
  html: PropTypes.string,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
}

export default About;
