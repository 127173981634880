import React from "react"
import classnames from "classnames"
import PropTypes from "prop-types"

import { Container } from "../index"
import styles from "./features.module.scss"

const Features = ({ html, title, subTitle }) => (
  <section id="features" className={styles.features}>
    <Container className={styles.container}>
      <div className={styles.row}>
        <div className={styles.colLeft}>
          <h2 className="h1">{title}</h2>
          <p>{subTitle}</p>
        </div>
        <div className={classnames(styles.colRight, "html")} dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Container>
  </section>
)

Features.propTypes = {
  html: PropTypes.string,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
}

export default Features;
