import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import styles from "./questions.module.scss"

const Questions = ({
  questions,
  aLabel,
  qLabel
}) => (
  <div className={styles.questions}>
    {
      questions.map((item, index) => (
        <React.Fragment key={`question_${index}`}>
          <div className={styles.question}>
            <p><span><strong>{qLabel}</strong></span><strong>{item.question}</strong></p>
            <p className={styles.answer}><span>{aLabel}</span>{item.answer}</p>
          </div>
          {
            ((index+1)%2 === 0) && <hr className={classnames(styles.divider, styles.subtle)} />
          }
        </React.Fragment>
      ))
    }
    {
      (questions.length % 2 !== 0) && <hr className={classnames(styles.divider, styles.subtle)} />
    }
  </div>
)

Questions.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({
    question: PropTypes.string,
    answer: PropTypes.string
  })).isRequired
}

export default Questions
