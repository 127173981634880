import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import classnames from "classnames"

import { mediaType } from "../../types"
import { Container, Slider, Overlay } from "../index"
import styles from "./media.module.scss"

class Media extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentMedia: 0,
      overlayOpen: false
    };
  }

  onOverlayClose = () => {
    this.setState({
      overlayOpen: false,
    })
  }

  onUpdateCurrent = (index) => {
    this.setState({
      currentMedia: index
    })
  }

  onMediaClick = (index) => {
    this.setState({
      overlayOpen: true,
      currentMedia: index
    })
  }

  render() {
    const { 
      title,
      subTitle,
      download,
      media
    } = this.props;
    const { currentMedia, overlayOpen } = this.state;
    return (
      <section id="media" className={styles.media}>
        <div className={styles.inner}>
          <Container className={styles.container}>
            <header className={styles.header}>
              <div className={styles.title}>
                <h4>{subTitle}</h4>
                <h2 className="h1">{title}</h2>
              </div>
              <a 
                className={classnames("button", styles.downloadDesktop)}
                href={download.link}
                download
              >{download.label}</a>
            </header>
            <Slider media={media} onClick={this.onMediaClick} />
            <a 
              className={classnames("button", styles.downloadMobile)}
              href={download.link}
              download
            >{download.label}</a>
          </Container>
        </div>
        <Overlay
          current={currentMedia}
          media={media}
          visible={overlayOpen}
          onClose={this.onOverlayClose}
          updateCurrent={this.onUpdateCurrent}
        />
      </section>
    )
  }
}

export const query = graphql`
  fragment Media on MarkdownRemark {
    frontmatter {
      download {
        label
        link
      }
      media {
        imgSrc {
          childImageSharp {
            fluid(maxWidth: 1280, maxHeight: 720) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

Media.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  download: PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string
  }),
  media: mediaType,
}

export default Media;
