import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import classnames from "classnames"
import throttle from "lodash.throttle"

import { Overlay } from "../index"
import styles from "./intro.module.scss"
import logoSrc from "./receiver-2-logo@2x.png"
import videoSrc from "./receiver-2-homepage-video.mp4"
import posterSrc from "./receiver-2-homepage-poster.jpg"
import Icon, { ICON_TYPES } from "../social/icon"
import { isDesktop } from "../../utils/responsive"

class Intro extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      overlayOpen: false,
      isDesktop: false,
    };
  }

  handleResize = () => {
    this.setState({ 
      isDesktop: isDesktop()
    });
  }

  throttleResize = throttle(this.handleResize, 250);

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttleResize, false);
  }

  componentDidMount() {
    this.setState({ 
      isDesktop: isDesktop()
    });
    window.addEventListener('resize', this.throttleResize, false);
  }

  onOverlayClose = () => {
    this.setState({
      overlayOpen: false,
    })
    this.playVideo()
  }

  onClick = (event) => {
    event.preventDefault()
    this.setState({
      overlayOpen: true,
    })
    this.pauseVideo()
  }

  playVideo = () => {
    if(!this.state.isDesktop) return;
    this.refs.videoPlayer.play();
  }

  pauseVideo = () => {
    if(!this.state.isDesktop) return;
    this.refs.videoPlayer.pause();
  }

  render() {
    const { 
      trailer,
      buyNow,
    } = this.props;
    const { 
      overlayOpen,
      isDesktop
    } = this.state;
    return (
      <section id="home" className={styles.intro}>
        <div className={styles.inner}>
          <img className={styles.logo} src={logoSrc} alt="Receiver 2" width={442} />
          <a 
            href={trailer.link}
            className={classnames("button", styles.button)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => this.onClick(e)}
          >
            <span className={styles.playIcon} />
            {trailer.label}
          </a>
          <a
            href={buyNow.link}
            target="_blank"
            rel="noopener noreferrer"
            className={classnames("button", styles.button)}
          >
            <Icon type={ICON_TYPES.STEAM} className={styles.icon} />
            {buyNow.label}
          </a>
        </div>
        {
          isDesktop && <video
            ref="videoPlayer"
            className={styles.video}
            playsInline autoPlay muted loop
            poster={posterSrc}
          >
            <source src={videoSrc} type="video/mp4" />
          </video>
        }
        <Overlay
          current={0}
          media={[{
            youtubeLink: trailer.link
          }]}
          visible={overlayOpen}
          onClose={this.onOverlayClose}
        />
      </section>
    )
  }
}

export const query = graphql`
  fragment Intro on MarkdownRemark {
    frontmatter {
      trailer {
        label
        link
      }
      buyNow {
        label
        link
      }
    }
  }
`

Intro.propTypes = {
  trailer: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string
  }),
  buyNow: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string
  }),
}

export default Intro;
