import React from "react"
import PropTypes from "prop-types"

import styles from "./newsletter.module.scss"

const Newsletter = ({ title, description, email, button }) => (
  <div className={styles.newsletter}>
    <h3>{title}</h3>
    <p>{description}</p>
    <form
      action="https://wolfire.us3.list-manage.com/subscribe/post?u=5bc2fbddada9ebe48f22355f3&amp;id=ccc4bd6c81"
      method="post"
      name="mc-embedded-subscribe-form"
      target="_blank"
      noValidate
    >			
      <input
        type="email"
        name="EMAIL"
        className={styles.input}
        placeholder={email}
        required 
      />
      <div className={styles.hidden} aria-hidden="true">
        <input type="text" name="b_5bc2fbddada9ebe48f22355f3_ccc4bd6c81" tabIndex="-1" />
      </div>
      <button className="button" type="submit">{button}</button>
    </form>
  </div>
)

export const newsletterTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  email: PropTypes.string,
  button: PropTypes.string,
}

Newsletter.propTypes = newsletterTypes;

export default Newsletter;
