import React from "react"
import PropTypes from "prop-types"

import styles from "./emailUs.module.scss"

const EmailUs = ({ description, button, address }) => (
  <div className={styles.emailUs}>
    <p>{description}</p>
    <a className="button" href={`mailto:${address}`} title={button}>{button}</a>
  </div>
)

export const emailUsType = {
  description: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
}

EmailUs.propTypes = emailUsType

export default EmailUs
