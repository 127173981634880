import Header from './header/header';
import SEO from './seo/seo';
import Layout from './layout/layout';
import Footer from './footer/footer';
import Container from './container/container';
import Social from './social/social';
import SubFooter from './subFooter/subFooter';
import Newsletter from './newsletter/newsletter';
import About from "./about/about";
import Features from "./features/features";
import Questions from "./questions/questions";
import EmailUs from "./emailUs/emailUs";
import Intro from "./intro/intro";
import Media from "./media/media";
import Slider from "./slider/slider";
import Overlay from "./overlay/overlay";
import Portal from "./portal/portal";
import LocalizedLink from "./localizedLink/LocalizedLink";
import Language from "./language/Language";

export {
  Header,
  SEO,
  Layout,
  Footer,
  Container,
  Social,
  SubFooter,
  Newsletter,
  About,
  Features,
  Questions,
  EmailUs,
  Intro,
  Media,
  Slider,
  Overlay,
  Portal,
  LocalizedLink,
  Language
}