import React from "react"
import PropTypes from "prop-types"

import styles from "./subFooter.module.scss"
import { Container, Newsletter, Social } from "../index"
import { newsletterTypes, socialTypes } from "../../types"
import receiverSrc from "./receiver-game-promo-tile.jpg"

const SubFooter = ({ social, newsletter }) => {
  return (
    <footer className={styles.subFooter}>
      <Container className={styles.container}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Newsletter {...newsletter} />
          </div>
          <div className={styles.col}>
            <h3>{social.title}</h3>
            <p>{social.description}</p>
            <Social className={styles.social}/>
          </div>
          <div className={styles.col}>
            <h3>Receiver</h3>
            <a href="https://www.wolfire.com/receiver" target="_blank" rel="noopener noreferrer" className={styles.promo}>
              <img src={receiverSrc} alt="Receiver" />
            </a>
          </div>
        </div>
      </Container>
    </footer>
  )
}

SubFooter.propTypes = {
  social: PropTypes.shape(socialTypes),
  newsletter: PropTypes.shape(newsletterTypes),
}

export default SubFooter