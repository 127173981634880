const MOBILE_MAX_WIDTH = 1023;

export const isDesktop = () => {
  if (typeof window !== "undefined") {
    if (window.innerWidth > MOBILE_MAX_WIDTH) {
      return true;
    }
  }
  return false;
}

export const isMobile = () => {
  if (typeof window !== "undefined") {
    if (window.innerWidth <= MOBILE_MAX_WIDTH) {
      return true;
    }
  }
  return false;
}
