import React from "react"
import PropTypes from "prop-types"

import styles from "./footer.module.scss"
import { Container, LocalizedLink, Language } from "../index"
import logoSrc from "./receiver-2-logo@2x.png"
import wolfireLogoSrc from "./wolfire-games-logo@2x.png"

const Wolfire = ({ className }) => (
  <li className={className}>
    <a href="http://www.wolfire.com" target="_blank" className={styles.wolfireLogo} rel="noopener noreferrer">
      <img src={wolfireLogoSrc} alt="Wolfire Games" width="160" height="54" />
    </a>
    © {new Date().getFullYear()}&nbsp;
    <a href="http://www.wolfire.com" target="_blank" rel="noopener noreferrer">
      Wolfire Games
    </a>
  </li>
)

const Footer = ({ footerLinks, designedBy, languageLabel }) => (
  <footer className={styles.footer}>
    <Container className={styles.withShape}>
      <LocalizedLink to="/" className={styles.logo}>
        <img src={logoSrc} alt="Receiver 2" />
      </LocalizedLink>
      <nav className={styles.nav}>
        <ul>
          <Wolfire className={styles.mobileWolfire} />
          {
            footerLinks.map(({ link, external, title }) => {
              const aProps = {
                href: link,
                title
              }
              if(external) {
                aProps.target = "_blank"
                aProps.rel = "noopener noreferrer"
              }
              return (
                <li key={title}>
                  <a {...aProps}>{title}</a>
                </li>
              )
            })
          }
          <li>
            <Language label={languageLabel} />
          </li>
        </ul>
      </nav>
      <ul className={styles.credits}>
        <Wolfire className={styles.desktopWolfire} />
        <li className={styles.fullyIllustrated}>
          {designedBy}&nbsp;
          <a href="http://www.fullyillustrated.com" target="_blank" rel="noopener noreferrer">
            Fully Illustrated
          </a>
        </li>
      </ul>
    </Container>
  </footer>
)

export const footerTypes = {
  footerLinks: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
    external: PropTypes.bool,
  })).isRequired,
  designedBy: PropTypes.string.isRequired,
  languageLabel: PropTypes.string.isRequired,
}

Footer.propTypes = footerTypes

export default Footer;
