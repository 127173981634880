import PropTypes from "prop-types"

import { headerTypes } from '../components/header/header';
import { footerTypes } from '../components/footer/footer';
import { socialTypes } from '../components/social/social';
import { newsletterTypes } from '../components/newsletter/newsletter';

const pageTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired,
  data: PropTypes.shape({
    allFile: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
          name: PropTypes.string,
          childMarkdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object
          })
        }).isRequired
      })).isRequired
    }).isRequired
  }).isRequired
}

const childImageSharpType = PropTypes.shape({
  fluid: PropTypes.shape({
    base64: PropTypes.string,
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string,
    aspectRatio: PropTypes.number
  })
})

export const mediaType = PropTypes.arrayOf(PropTypes.shape({
  imgSrc: PropTypes.shape({
    childImageSharp: childImageSharpType,
  }),
  youtubeLink: PropTypes.string
}))

export {
  headerTypes,
  footerTypes,
  socialTypes,
  newsletterTypes,
  pageTypes,
}