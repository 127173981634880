import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import classnames from "classnames"

import styles from "./overlay.module.scss"
import { mediaType } from "../../types"
import { Portal } from "../index" 

const KEYS = {
  ESC: 27,
  RIGHT: 39,
  LEFT: 37
}

class Overlay extends React.PureComponent {
  constructor(props) {
    super(props);

    this.totalMedia = props.media.length - 1;
    this.root = typeof document !== `undefined` ? document.getElementsByTagName( 'html' )[0] : null;
  }

  handleKeyDown = (event) => {
    const { keyCode } = event;
    switch(keyCode) {
      case KEYS.ESC:
        this.onClose()
        break;
      case KEYS.LEFT:
        this.prev();
        break;
      case KEYS.RIGHT:
        this.next();
        break;
      default:
        return;     
    }
  }

  componentDidMount() {
    if(this.props.visible){
      this.root.classList.add(styles.noScroll);
      window.addEventListener("keydown", this.handleKeyDown);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  componentDidUpdate(prevProps){ 
    if(this.props.visible){
      this.root.classList.add(styles.noScroll);
      window.addEventListener("keydown", this.handleKeyDown);
    } else {
      this.root.classList.remove(styles.noScroll);
      window.removeEventListener("keydown", this.handleKeyDown);
    }
  }

  prev = () => {
    const { updateCurrent, current } = this.props;
    if(current === 0) return;
    updateCurrent(current - 1)
  }

  next = () => {
    const { updateCurrent, current } = this.props;
    if(current === this.totalMedia) return;
    updateCurrent(current + 1)
  }

  onClose = () => {
    this.props.onClose()
  }

  render() {
    const { visible, media, current } = this.props;
    if(!visible) {
      return null;
    }
    const item = media[current];
    return (
      <Portal>
        <div className={styles.overlay}>
          <button className={styles.close} onClick={this.onClose} type="button">✕</button>
          <div className={styles.content}>
            {
              item.youtubeLink
                ? (
                    <iframe
                      src={`${item.youtubeLink}?autoplay=1`}
                      frameBorder="0"
                      className={styles.video}
                      title="Receiver 2 - Youtube video"
                    />
                  )
                : (
                    <Img
                      className={styles.img}
                      fluid={item.imgSrc.childImageSharp.fluid}
                      alt=""
                    />
                  )
            }
          </div>
          <button className={styles.bg} onClick={this.onClose} type="button" />
          {
            (this.totalMedia > 0) && (
              <>
                <button
                  type="button"
                  disabled={current === 0}
                  className={classnames(styles.btn, styles.prev)}
                  onClick={this.prev}
                ><span /></button>
                <button
                  type="button"
                  disabled={current === this.totalMedia}
                  className={classnames(styles.btn, styles.next)}
                  onClick={this.next}
                ><span /></button>
              </>
            )
          }
        </div>
      </Portal>
    )
  }
}

Overlay.propTypes = {
  media: mediaType,
  current: PropTypes.number,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  updateCurrent: PropTypes.func,
}

Overlay.defaultProps = {
  current: 0,
  visible: false
}

export default Overlay
