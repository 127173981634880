import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import { Header, Footer, SubFooter } from "../index"
import { headerTypes, footerTypes, socialTypes, newsletterTypes } from "../../types"
import { LocaleContext } from "../../contexts"
import styles from "./layout.module.scss"

export const query = graphql`
  fragment Site on MarkdownRemark {
    frontmatter {
      title
      description
      designedBy
      languageLabel
      newsletter {
        button
        description
        email
        title
      }
      homeSectionsLabels {
        about
        features
        home
        media
      }
      headerLinks {
        featured
        link
        title
      }
      footerLinks {
        external
        link
        title
      }
      social {
        description
        title
      }
    }
  }
`

const Layout = ({ children, className, contentPage, content, locale }) => {
  const { social, headerLinks, homeSectionsLabels, newsletter, footerLinks, designedBy, languageLabel } = content;
  return (
    <LocaleContext.Provider value={locale}>
      <div className={classnames(styles.layout, {
          [styles.contentPage]: contentPage
        }, className)}>
        <Header 
          isHomepage={!contentPage}
          headerLinks={headerLinks}
          homeSectionsLabels={homeSectionsLabels}
        />
        <main>{children}</main>
      </div>
      <SubFooter
        social={social}
        newsletter={newsletter}
      />
      <Footer
        footerLinks={footerLinks}
        designedBy={designedBy}
        languageLabel={languageLabel}
      />
    </LocaleContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string.isRequired,
  className: PropTypes.string,
  contentPage: PropTypes.bool,
  content: PropTypes.shape({
    social: PropTypes.shape(socialTypes),
    newsletter: PropTypes.shape(newsletterTypes),
    ...headerTypes,
    ...footerTypes
  })
}

Layout.defaultProps = {
  contentPage: true
}

export default Layout
