import React from "react"
import Img from "gatsby-image"
import classnames from "classnames"
import PropTypes from "prop-types"
import throttle from "lodash.throttle"

import styles from "./slider.module.scss"
import { mediaType } from "../../types"
import chunkArray from "../../utils/chunkArray"
import { isMobile } from "../../utils/responsive"

class Slider extends React.PureComponent {
  constructor(props) {
    super(props);

    this.mediaSets = chunkArray(props.media, 2);
    this.totalSets = this.mediaSets.length;

    this.state = {
      firstSet: 1,
      lastSet: 3,
      setsInView: 3,
    };
  }

  handleResize = () => {
    const isMob = isMobile();
    this.setState({
      setsInView: isMob ? 2 : 3,
      lastSet: isMob ? 2 : 3,
    })
  }

  throttleResize = throttle(this.handleResize, 500);

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttleResize, false);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.throttleResize, false);
  }

  prevSet = () => {
    this.setState(prevState => ({ 
      firstSet: prevState.firstSet - 1,
      lastSet: prevState.lastSet - 1,
    }))
  }

  nextSet = () => {
    this.setState(prevState => ({ 
      firstSet: prevState.firstSet + 1,
      lastSet: prevState.lastSet + 1,
    }))
  }

  toSet = (set) => {
    this.setState({ 
      firstSet: set,
      lastSet: set + 1,
    })
  }

  onClick = (event, imgSrc) => {
    event.preventDefault();
    const index = this.props.media.findIndex((e) => imgSrc === e.imgSrc.childImageSharp.fluid.src)
    this.props.onClick(index);
  }

  createPips = () => {
    const { firstSet } = this.state;
    const items = [];
    for (let i = 1; i < this.totalSets; i++) {
      items.push(<li key={`pip-${i}`}>
        <button
          onClick={() => this.toSet(i)}
          type="button"
          className={classnames(styles.pip, {
            [styles.activePip]: firstSet === i
          })}
        />
      </li>)
    }
    return (
      <ul className={styles.pips}>
        {items}
      </ul>
    )
  }

  render() {
    const { setsInView, firstSet, lastSet } = this.state;
    const xPos = (firstSet - 1) * (100 / setsInView);
    return (
      <>
        <div className={styles.slider}>
          <div className={styles.mask}>
            <ul
              className={styles.track}
              style={{
                transform: `translateX(-${xPos}%)` 
              }}
            >
              {
                this.mediaSets.map((set, index) => (
                  <li className={styles.set} key={`set_${index}`}>
                    {
                      set.map((item, index) => {
                        const { fluid } = item.imgSrc.childImageSharp;
                        return(
                          <a
                            href={item.youtubeLink ? item.youtubeLink : fluid.src}
                            className={styles.item}
                            key={`media_${index}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => this.onClick(e, fluid.src)}
                          >
                            <Img 
                              className={styles.img}
                              fluid={fluid}
                              alt=""
                            />
                          </a>
                        )
                    })
                    }
                  </li>
                ))
              }
            </ul>
          </div>
          {
            (this.totalSets > setsInView) && (
              <>
                <button
                  type="button"
                  disabled={firstSet === 1}
                  className={classnames(styles.btn, styles.prev)}
                  onClick={this.prevSet}
                ><span /></button>
                <button
                  type="button"
                  disabled={lastSet === this.totalSets}
                  className={classnames(styles.btn, styles.next)}
                  onClick={this.nextSet}
                ><span /></button>
                {this.createPips()}
              </>
            )
          }
        </div>
      </>
    )
  }
}

Slider.propTypes = {
  media: mediaType,
  onClick: PropTypes.func.isRequired
}

export default Slider
