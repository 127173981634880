import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import throttle from "lodash.throttle"

import { Container, Social, LocalizedLink } from "../index"
import styles from "./header.module.scss"
import logoSrc from "./receiver-2-site-logo@2x.png"
import { isMobile } from "../../utils/responsive"

const SECTIONS = {
  INTRO: {
    name: "home",
    endY: 0,
  },
  ABOUT: {
    name: "about",
    endY: 0,
  },
  MEDIA: {
    name: "media",
    endY: 0,
  },
  FEATURES: {
    name: "features",
    endY: 0,
  },
}

const yThreshold = 250;

class Header extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeSection: null,
      observe: true,
      mobileNavOpen: false,
    }

    const { homeSectionsLabels } = props;
    SECTIONS.INTRO.label = homeSectionsLabels.home;
    SECTIONS.ABOUT.label = homeSectionsLabels.about;
    SECTIONS.MEDIA.label = homeSectionsLabels.media;
    SECTIONS.FEATURES.label = homeSectionsLabels.features;
  }

  toggleMobileNav = () => {
    this.setState(prevState => ({
      mobileNavOpen: !prevState.mobileNavOpen
    }));
  }

  updateSectionY = () => {
    Object.keys(SECTIONS).forEach((section) => {
      const element = document.getElementById(SECTIONS[section].name);
      const endX = element.offsetTop + element.offsetHeight;
      SECTIONS[section].endY = endX;
    });
  }

  handleClick = (event, id) => {
    if(this.props.isHomepage) {
      event.preventDefault();
      const element = document.getElementById(id);
      element.scrollIntoView({behavior:"smooth", block: "start"});
    }
    this.setState({
      activeSection: id,
      observe: false,
    });
    clearTimeout(this.observeTimer);
    this.observeTimer = setTimeout(() => {
      this.setState({observe: true});
    }, 850);
  }

  getActiveSection = (yPos) => {
    if(yPos < SECTIONS.INTRO.endY - yThreshold) {
      return SECTIONS.INTRO.name;
    }
    if(yPos < SECTIONS.ABOUT.endY - yThreshold) {
      return SECTIONS.ABOUT.name;
    }
    if(yPos < SECTIONS.MEDIA.endY - yThreshold) {
      return SECTIONS.MEDIA.name;
    }
    return SECTIONS.FEATURES.name;
  }

  handleScroll = () => {
    if(!this.state.observe) return;
    const { scrollY } = window;
    const activeSection = this.getActiveSection(scrollY);
    if(activeSection !== this.state.activeSection) {
      this.setState({
        activeSection
      })
    }
  }

  throttleScroll = throttle(this.handleScroll, 250);

  componentWillUnmount() {
    clearTimeout(this.observeTimer);
    window.removeEventListener('scroll', this.throttleScroll, false);
  }

  componentDidMount() {
    if(!window || !this.props.isHomepage || isMobile()) return;
    window.addEventListener('scroll', this.throttleScroll, false);
    this.updateSectionY();
    this.handleScroll();
  }

  render() { 
    const { activeSection, mobileNavOpen } = this.state;
    const { headerLinks } = this.props;
    return (
      <header className={styles.header}>
        <Container className={styles.container}>
          <LocalizedLink to="/" className={styles.logo}>
            <img src={logoSrc} alt="Receiver 2 logo" />
          </LocalizedLink>
          <nav className={classnames(styles.nav,
            {
              [styles.open]: mobileNavOpen
            })}>
            <ul className={styles.links}>
              {
                Object.keys(SECTIONS).map((section, index) => (
                  <li className={classnames({
                    [styles.jumpLink]: index !== 0
                  })} key={`section_${index}`}>
                    <LocalizedLink
                      to={`/#${SECTIONS[section].name}`}
                      onClick={(e) => this.handleClick(e, SECTIONS[section].name)}
                      className={classnames({
                        [styles.active]: activeSection === SECTIONS[section].name
                      })}
                    >
                      {SECTIONS[section].label}
                    </LocalizedLink>
                  </li>
                ))
              }
              {
              headerLinks.map(({ link, featured, title }) => {
                const linkProps = {
                  to: `${link}/`,
                  activeClassName: styles.active,
                }
                if(featured) {
                  linkProps.className = styles.buyNow
                  return null
                }
                return (
                  <li key={title}>
                    <LocalizedLink {...linkProps}>{title}</LocalizedLink>
                  </li>
                )
              })
            }
            </ul>
            <Social className={styles.mobileSocial}/>
          </nav>
          <button
            onClick={this.toggleMobileNav}
            type="button"
            className={classnames(styles.menu, {
              [styles.on]: mobileNavOpen
            })}
          >
            <span className={styles.burger}>
              <span className={styles.top} />
              <span className={styles.middle} />
              <span className={styles.extra} />
              <span className={styles.bottom} />
            </span>
          </button>
        </Container>
      </header>
    )
  }
}

export const headerTypes = {
  headerLinks: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
    featured: PropTypes.bool
  })).isRequired,
  homeSectionsLabels: PropTypes.shape({
    home: PropTypes.string,
    about: PropTypes.string,
    media: PropTypes.string,
    features: PropTypes.string,
  }).isRequired
}

Header.propTypes = {
  isHomepage: PropTypes.bool,
  ...headerTypes,
}

Header.defaultProps = {
  isHomepage: false
}

export default Header