import React from "react"
import PropTypes from "prop-types"

import styles from "./language.module.scss"
import language from "./language.svg"
import locales from '../../constants/locales'
import { LocaleContext } from "../../contexts"
import { redirectToLocalisedPage } from "../../utils/language";

const Language = ({ label: languageLabel }) => {
  const pageLocale = React.useContext(LocaleContext);
  const onChange = (e) => {
    const lang = e.target.value;
    redirectToLocalisedPage(lang)
  }
  return (
    <div className={styles.language}>
      <img src={language} alt="" height={20} width={20} />
      <label htmlFor="language">{languageLabel}:</label>
      <select name="language" id="language" onChange={onChange} defaultValue={pageLocale}>
        {
          Object.keys(locales).map(l => {
            const { locale, label } = locales[l];
            return (
              <option
                key={locale}
                value={locale}
              >
                {label}
              </option>
            )
          })
        }
      </select>
    </div>
  )
}


Language.propTypes = {
  label: PropTypes.string.isRequired,
}

export default Language;
