import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import styles from "./container.module.scss"

const Container = ({ children, className }) => {
  return (
    <div className={classnames(styles.container, className)}>
      {children}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Container
