const chunkArray = (sourceArray, chunk_size) => {
  let index = 0;
  const arrayLength = sourceArray.length;
  const tempArray = [];
  
  for (index = 0; index < arrayLength; index += chunk_size) {
      const myChunk = sourceArray.slice(index, index+chunk_size);
      tempArray.push(myChunk);
  }

  return tempArray;
}

export default chunkArray