import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import locales from '../../constants/locales'
import { LocaleContext } from "../../contexts"

const LocalizedLink = ({ to, children, ...props }) => {
  const locale = React.useContext(LocaleContext);
  const path = locales[locale].default ? to : `/${locale}${to}`

  return <Link {...props} to={path}>{children}</Link>
}

LocalizedLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default LocalizedLink